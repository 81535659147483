<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Map Layer</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          title="Share This App"
          variant="outline-secondary"
          @click="shareViaWebShare">
          <b-icon-share />
        </b-button>
        <b-button
          @click="startTour"
          variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <contact-expert class="contactAExpert" />
      <b-row>
        <b-col md="8" class="mb-3">
          <layer-selector
            class="layer-types"
            v-if="SelectedLayerType"
            :LayerTypes="LayerTypes"
          />
          <div id="leaflet-map">
            <leaflet-map />
          </div>
        </b-col>
        <b-col md="4" class="mt-md-0 mt-sm-2">
          <layer-description
            :SelectedLayerType="SelectedLayerType"
            showMapLayerInfo="true"/>
        </b-col>
      </b-row>
    <v-tour
      class="mapLayerTour"
      name="mapLayerTour"
      :options="tourOptions"
      :steps="mapLayerTourSteps"
    />
    <b-modal
      id="modal-1"
      title="Share This App"
      ref="fallback-modal"
      @ok="handleOk">
      <b-form-textarea
        id="share-app-fallback"
        ref="toCopy"
        v-model="shareUrl"
        rows="1"
        max-rows="6"
      ></b-form-textarea>
      <template #modal-footer="{ ok}">
        <b-button
          size="sm"
          variant="primary"
          @click="ok()">
          Copy URL to Clipboard
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue';
import VueTour from 'vue-tour';
import { mapGetters, mapMutations } from 'vuex';

import {
  BButton,
  BCol,
  BContainer,
  BIconShare,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue';

import LayerDescription from '@/components/LayerDescription.vue';
import LayerSelector from '@/components/LayerSelector.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import oidcAuth from '@/mixins/oidcAuth';
import ContactExpert from '@/components/ContactExpert.vue';

Vue.use(VueTour);

export default {
  components: {
    BButton,
    BCol,
    BContainer,
    BIconShare,
    BFormTextarea,
    BRow,
    ContactExpert,
    LayerDescription,
    LayerSelector,
    LeafletMap,
  },
  computed: {
    ...mapGetters({
      LayerTypes: 'mapLayersStore/LayerTypes',
      SelectedLayerType: 'selectedMapLayersStore/SelectedLayerType',
    }),
    mapLayerTourSteps() {
      return [
        {
          content:
            'Switch map layer/s on or off to display your content.',
          header: {
            title: 'Select Map layer',
          },
          params: {
            placement: 'auto',
          },
          target: '.layer-types',
        },
      ];
    },
    shareUrl() {
      const { protocol, host, pathname } = window.location;
      const url = [`${protocol}//${host}${pathname}`];
      if (this.SelectedLayerType || this.SelectedLayerType.length) {
        let layers = this.SelectedLayerType.map((l) => l.value).join();
        layers = `?layers=${layers}`;
        url.push(layers);
      }
      return url.join('');
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour (Esc)',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: true,
      };
    },
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  methods: {
    ...mapMutations({
      SetSelectedLayerType: 'selectedMapLayersStore/SetSelectedLayerType',
    }),
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator.share({
          title: document.title,
          url: this.shareUrl,
        }).then().catch(() => {
        // Handle the error!
        });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    startTour() {
      this.$tours.mapLayerTour.start();
    },
    setRequestedLayers() {
      const { layers } = this.$route.query;
      if (!layers) {
        return;
      }
      const layerParams = layers.split(',');
      const requestedLayers = this.LayerTypes
        .filter((type) => layerParams.some((param) => param === type.value));
      if (!requestedLayers || !requestedLayers.length) {
        return;
      }
      this.SetSelectedLayerType(requestedLayers);
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenMapLayerTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenMapLayerTour', true);
      }
    },
  },
  mounted() {
    this.tourConfigure();
    this.setRequestedLayers();
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    /**
     * Set the SelectedLayerType to points,
     * Set it only when it is null, the user might have already selected
     * a different type via the request query param
     */
    if (!this.SelectedLayerType.length) {
      const type = this.LayerTypes.find((t) => t.value === 'iris:polygon');
      this.SetSelectedLayerType([type]);
    }
  },
  mixins: [oidcAuth],
  name: 'map-layer',
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
#leaflet-map {
  position: relative;
  width: 100%;
  height: 75vh;
}
.layer-types {
  position: absolute;
  top: 4px;
  right: 19px;
  z-index: 500;
  cursor: pointer;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
