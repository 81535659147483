<template>
  <div
    class="map-container"
    :id="leafletContainerId"
  >
    Loading Map...
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';
import 'leaflet.nontiledlayer/dist/NonTiledLayer';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      SelectedLayerType: 'selectedMapLayersStore/SelectedLayerType',
    }),
    isMapLayer() {
      return ['/map-layer'].includes(window.location.pathname);
    },
    isHeatMap() {
      return ['/map-layer/heat'].includes(window.location.pathname);
    },
    center() {
      return this.isMapLayer ? [-29.728123713149685, 31.0580257607013] : [-29.1181673, 26.2215857];
    },
    countryBoundingBox() {
      return this.isMapLayer ? [[-29.702438005664956, 31.04816840927625],
        [-29.76022989437735, 31.060081086578748]] : [[-29.239675, 26.082916],
        [-29.036060, 26.371651]];
    },
    leafletContainerId: () => 'leaflet-container',
  },
  data() {
    return {
      agMapLoaded: false,
      leafletMap: null,
      mapLayerGroup: null,
    };
  },
  methods: {
    checkIfAgMapLoaded() {
      this.agMapLoaded = !!window.AfriGIS;
      if (this.agMapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfAgMapLoaded();
      }, 500);
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }

      this.leafletMap = window.AfriGIS.map(this.leafletContainerId, {
        center: L.latLng(this.center),
        authkey: process.env.VUE_APP_LEAFLET_KEY,
        mapType: 'vector',
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: this.isHeatMap ? 12 : 17,
        minZoom: this.isHeatMap ? 8 : 3,
        zoom: this.isHeatMap ? 10 : 17,
        zoomControl: true,
      });
      this.leafletMap.fitBounds(this.countryBoundingBox);
      this.leafletMap.zoomControl.setPosition('bottomright');
      this.drawLayers(this.SelectedLayerType);
    },
    drawLayers(selectedLayers) {
      const baseUrl = `${process.env.VUE_APP_OGC_LAYER_BASE_URL}?`;
      let wmsLayer;
      if (this.isNonTiledLayer) {
        wmsLayer = L.nonTiledLayer.wms(
          baseUrl,
          {
            authkey: process.env.VUE_APP_LEAFLET_KEY,
            transparent: true,
            format: 'image/png',
            layers: selectedLayers.map((m) => m.value).toString(),
            tiled: true,
            styles: selectedLayers.map((m) => m.style).toString(),
            pane: 'tilePane',
          },
        );
      } else {
        wmsLayer = L.tileLayer.wms(
          baseUrl,
          {
            authkey: process.env.VUE_APP_LEAFLET_KEY,
            transparent: true,
            format: 'image/png',
            layers: selectedLayers.map((m) => m.value).toString(),
            tiled: true,
            styles: selectedLayers.map((m) => m.style).toString(),
            version: '1.1.0',
          },
        );
      }
      if (this.mapLayerGroup == null) {
        this.mapLayerGroup = L.layerGroup();
        this.mapLayerGroup.addTo(this.leafletMap);
      }
      this.mapLayerGroup.clearLayers();
      this.mapLayerGroup.addLayer(wmsLayer);
    },
  },
  mounted() {
    this.checkIfAgMapLoaded();
  },
  name: 'LeafletMap',
  props: {
    isNonTiledLayer: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    agMapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    SelectedLayerType(newLayers) {
      this.drawLayers(newLayers);
    },
  },
};
</script>

<style>
.map-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .leaflet-bar a{
    background-color: var(--primary) !important;
    color: #fff !important;
  }

</style>
