<template>
  <div>
    <b-alert
      class="container container-between-stickies"
      show
      variant="info"
      v-if="!OidcUser"
    >
      <b-spinner label="Loading..." small />
      Loading User Information
    </b-alert>
    <router-view class="container-between-stickies" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BAlert, BSpinner } from 'bootstrap-vue';
import { AuthMixin } from '@afrigis/iris-vuex-auth-store';

export default {
  components: {
    BAlert,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      OidcUser: 'authStore/OidcUser',
    }),
  },
  methods: {
    ...mapActions({
      AuditWrite: 'auditStore/Log',
    }),
  },
  mounted() {
    // Get View Name, and add space after each Capital letter eg. 'Heat Map Layers'.
    const capabilityName = this.$router.currentRoute.name.replace(/([A-Z])/g, ' $1').trim();
    this.AuditWrite(capabilityName.toLowerCase());
  },

  name: 'App',
  mixins: [AuthMixin],
};
</script>

<style>
#share-app-fallback {
  pointer-events: none;
  background-color: lightgrey;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
  .contactAExpert {
    display: none;
  }
}
.remove-border-card {
  border-top: 0 !important;
  border-color: white !important;
}
</style>
