<template>
  <div>
    <b-row v-if="SelectedLayers.length == 0">
      <b-col class="text-center mt-2">
        <p>No Layer Selected</p>
      </b-col>
    </b-row>
    <div :key="`legend-${index}`"
      v-for="(legend, index) in selectedLegends"
      v-else>
      <p class="text-center">{{legend.title}}</p>
      <b-row>
        <b-col>
          <img :src="legend.imgUrl" alt="Legend">
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue';

export default {
  components: {
    BCol,
    BRow,
  },
  computed: {
    authKey: () => process.env.VUE_APP_LEAFLET_KEY,
    baseUrl: () => process.env.VUE_APP_OGC_LAYER_BASE_URL,
    selectedLegends() {
      const params = '&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LEGEND_OPTIONS=forceLabels:on';

      return (this
        .SelectedLayers || [])
        .map((l) => ({
          title: l.text,
          imgUrl:
            `${this.baseUrl}?authkey=${this.authKey}${params}&STYLE=${l.style}&LAYER=${l.value}${l.scale ? `&scale=${l.scale}` : ''}&WIDTH=${l.width ?? 20}&HEIGHT=${l.height ?? 20}`,
        }));
    },
  },
  name: 'TheMapLegend',
  props: {
    SelectedLayers: {
      required: true,
      type: Array,
    },
  },
};
</script>
