import { GettersUtility } from '@afrigis/vuex-utilities';

const STATE_VAR_LAYER_TYPES = 'LayerTypes';
const actions = {};
const getters = {
  [STATE_VAR_LAYER_TYPES]: GettersUtility.get(STATE_VAR_LAYER_TYPES),
};

const state = {
  [STATE_VAR_LAYER_TYPES]: [
    {
      description: 'A point on a map is a single visual element representing a coordinate or location. Points are used to showcase a complex dataset in a visual way.',
      text: 'Points Layer',
      value: 'iris:point',
    },
    {
      description: 'Street Segments are linear features represented as lines on a map.',
      text: 'Streets Segment Layer',
      value: 'iris:line',
    },
    {
      description: 'This layer is visually represented with shapes or polygons. It can be presented according to different categories, depending on your needs. The Gated community layer is locations with or without access control that adheres to AfriGIS Data criteria.',
      text: 'Gated Community Layer',
      value: 'iris:polygon',
    },
  ],
};

export default {
  actions,
  getters,
  state,
};
