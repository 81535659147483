<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Thematic Map Layer</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          title="Share This App"
          variant="outline-secondary"
          @click="shareViaWebShare">
          <b-icon-share />
        </b-button>
        <b-button
          @click="startTour"
          variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <contact-expert class="contactAExpert" />
    <b-row>
      <b-col md="8" class="mb-3">
        <b-row class="top-control-row px-2 no-gutters pointer-events-none">
          <b-col md="7">
          </b-col>
          <b-col
            class="text-md-right"
            md="5"
          >
        <layer-selector
          class="layer-types pointer-events-all"
          v-if="SelectedLayerType"
          :LayerTypes="LayerTypes"
          single-selector
        />
        <legend-button
          class="pointer-events-all legend"
          :selected-layers="thematicLayerTypes"
          />
          </b-col>
        </b-row>
        <div id="leaflet-map">
          <leaflet-map :isNonTiledLayer="isNonTiledLayer" />
        </div>
      </b-col>
      <b-col md="4" class="mt-md-0 mt-sm-2">
        <layer-description
          class="layer-description"
          :SelectedLayerType="SelectedLayerType"
          showThematicLayerInfo="true"/>
      </b-col>
    </b-row>
    <v-tour
      class="thematicMapLayerTour"
      name="thematicMapLayerTour"
      :options="tourOptions"
      :steps="thematicMapLayerTourSteps"
    />
    <b-modal
      id="modal-1"
      title="Share This App"
      ref="fallback-modal"
      @ok="handleOk">
      <b-form-textarea
        id="share-app-fallback"
        ref="toCopy"
        v-model="shareUrl"
        rows="1"
        max-rows="6"
      ></b-form-textarea>
      <template #modal-footer="{ ok}">
        <b-button
          size="sm"
          variant="primary"
          @click="ok()">
          Copy URL to Clipboard
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue';
import VueTour from 'vue-tour';
import { mapGetters, mapMutations } from 'vuex';
import {
  BButton,
  BCol,
  BContainer,
  BFormTextarea,
  BIconShare,
  BRow,
} from 'bootstrap-vue';

import LayerDescription from '@/components/LayerDescription.vue';
import LayerSelector from '@/components/LayerSelector.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import LegendButton from '@/components/map/LegendButton.vue';
import oidcAuth from '@/mixins/oidcAuth';
import ContactExpert from '@/components/ContactExpert.vue';

Vue.use(VueTour);

export default {
  components: {
    BButton,
    BCol,
    BContainer,
    BIconShare,
    BFormTextarea,
    BRow,
    ContactExpert,
    LayerDescription,
    LayerSelector,
    LeafletMap,
    LegendButton,
  },
  computed: {
    ...mapGetters({
      LayerTypes: 'thematicMapLayersStore/LayerTypes',
      SelectedLayerType: 'selectedMapLayersStore/SelectedLayerType',
    }),
    thematicLayerTypes() {
      return this.SelectedLayerType;
    },
    thematicMapLayerTourSteps() {
      return [
        {
          content:
            'Switch the controller on or off to enable or disable the thematic layer on the map background.',
          header: {
            title: 'Controller',
          },
          params: {
            placement: 'auto',
          },
          target: '.layer-types',
        },
        {
          content:
            'A map background is the canvas used to display data on. The map background usually features content that do not change as often. Ensure you select the background best suited to your needs.',
          header: {
            title: 'Map background',
          },
          params: {
            placement: 'auto',
          },
          target: '.map-container',
        },
        {
          content:
            'This thematic map layer portray a specific theme by visualising data of a specific subject matter in relation to a geographic area.',
          header: {
            title: 'What the thematic map depicts',
          },
          params: {
            placement: 'auto',
          },
          target: '.map-container',
        },
        {
          content:
            'A legend makes it easier to understand the data displayed on the thematic map layer',
          header: {
            title: 'Legend',
          },
          params: {
            placement: 'auto',
          },
          target: '.legend',
        },
      ];
    },
    shareUrl() {
      const { protocol, host, pathname } = window.location;
      const url = [`${protocol}//${host}${pathname}`];
      if (this.SelectedLayerType || this.SelectedLayerType.length) {
        let layers = this.SelectedLayerType.map((l) => l.value).join();
        layers = `?layers=${layers}`;
        url.push(layers);
      }
      return url.join('');
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour (Esc)',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: true,
      };
    },
  },
  data() {
    return {
      isNonTiledLayer: false,
    };
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  methods: {
    ...mapMutations({
      SetSelectedLayerType: 'selectedMapLayersStore/SetSelectedLayerType',
    }),
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator.share({
          title: document.title,
          url: this.shareUrl,
        }).then().catch(() => {
        // Handle the error!
        });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    startTour() {
      this.$tours.thematicMapLayerTour.start();
    },
    setRequestedLayers() {
      const { layers } = this.$route.query;
      if (!layers) {
        return;
      }
      const layerParams = layers.split(',');
      const requestedLayers = this.LayerTypes
        .filter((type) => layerParams.some((param) => param === type.value));
      if (!requestedLayers || !requestedLayers.length) {
        return;
      }
      this.SetSelectedLayerType(requestedLayers);
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenThematicMapLayerTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenThematicMapLayerTour', true);
      }
    },
  },
  mounted() {
    this.tourConfigure();
    this.setRequestedLayers();
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    /**
     * Set the SelectedLayerType to points,
     * Set it only when it is null, the user might have already selected
     * a different type via the request query param
     */
    if (!this.SelectedLayerType.length) {
      const type = this.LayerTypes.find((t) => t.text === 'Demographic Boundary');
      this.SetSelectedLayerType([type]);
    }
  },
  mixins: [oidcAuth],
  name: 'thematic-map-layer',
  watch: {
    SelectedLayerType(newV) {
      if (!newV) return;
      if (!this.isNonTiledLayer && newV[0].description === 'Point Cluster') {
        this.isNonTiledLayer = true;
      } else if (this.isNonTiledLayer) {
        this.isNonTiledLayer = false;
      }
    },
  },
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
#leaflet-map {
  position: relative;
  width: 100%;
  height: 75vh;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
