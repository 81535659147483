<template>
  <b-dropdown
    no-caret
    right
    toggle-class="text-decoration-none"
    variant="secondary"
    class="mt-2 mr-2 legend-selector"
  >
    <template #button-content>
      <b-icon-key />
    </template>
      <b-container class="legend-container" fluid>
        <legend-content :selected-layers="SelectedLayers"/>
      </b-container>
  </b-dropdown>
</template>

<script>
import {
  BContainer,
  BDropdown,
  BIconKey,
} from 'bootstrap-vue';
import LegendContent from '@/components/map/LegendContent.vue';

export default {
  components: {
    BContainer,
    BDropdown,
    BIconKey,
    LegendContent,
  },
  name: 'LegendButton',
  props: {
    SelectedLayers: {
      required: true,
      type: Array,
    },
  },
};
</script>
<style scoped>
.legend-selector {
  position: absolute;
  top: 3px;
  right: 48px;
  z-index: 500;
  cursor: pointer;
}
</style>
