<template>
  <div>
    <b-card
      id="trust-us-card"
      :style="{ background: `url(${backgroundImage})` }"
      text-variant="white"
      class="mb-3"
    >
      <div class="content">
        <h5>Trust us for</h5>
        <ul>
          <li>SABS and ISO compliant Address data.</li>
          <li>Accurate and verified address results returned.</li>
          <li>
            Components available via API to integrate into your own solutions.
          </li>
          <li>Over 50 Million searchable address combinations.</li>
        </ul>
      </div>
    </b-card>
    <b-card
      class="remove-border-card">
        <h4>Detailed Description:</h4>
      <div v-if="SelectedLayerType && showMapLayerInfo">
        <div
          :key="`selected-layer${index}`"
          v-for="(selected, index) in SelectedLayerType"
        >
          <p class="font-weight-bold mb-0">
            {{ selected.text }}
          </p>
          <p>
            {{ selected.description }}
          </p>
        </div>
      </div>
      <p
         v-if="showHeatLayerInfo">
        This demo showcases Census Demographic data, specifically using Population as
        the variable in the Heat Maps. This shows population spread in the area.
        Depending on the message that needs to be conveyed the appearance of the map
        can differ using different map types applied on the same data.
      </p>
      <p
        v-if="showThematicLayerInfo">
        This demo showcases Census Demographic data, specifically using Population as
        the variable in the Thematic map. This shows population coverage in the area.
        Depending on the message that needs to be conveyed the appearance of the map
        can differ using different map types applied on the same data.
      </p>
    </b-card>
    <b-card class="remove-border-card">
      <h4>Possible uses:</h4>
      <ul class="text-secondary"
        v-if="showMapLayerInfo">
        <li>Showcase complex datasets in a visual way with point layers.</li>
        <li>Represent linear features on a map with street segments.</li>
        <li>Visually represent shapes or polygons according to different
          categories, depending on your needs.</li>
      </ul>
      <ul class="text-secondary"
        v-if="showHeatLayerInfo">
        <li>Spatial Heat Maps visualise data on a spatial level.</li>
        <li>
          These maps assist with cluster analysis or
          hotspot study to identify the high
          concentration areas and display them on a
          spatial reference like a map.
        </li>
      </ul>
      <ul class="text-secondary"
        v-if="showThematicLayerInfo">
        <li>Thematic Maps are used for easy to read data visualisation.</li>
        <li>See the visual distribution and the geographic patterns and trends in your data.</li>
        <li>Explain characteristics of a location.</li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

const backgroundImage = require('@/assets/TTB_Image_1920x300.webp');

export default {
  components: {
    BCard,
  },
  computed: {
    backgroundImage: () => backgroundImage,
  },
  props: {
    SelectedLayerType: {
      required: true,
      type: Object,
    },
    showMapLayerInfo: {
      default: false,
      type: Boolean,
    },
    showHeatLayerInfo: {
      default: false,
      type: Boolean,
    },
    showThematicLayerInfo: {
      default: false,
      type: Boolean,
    },
  },
  name: 'LayerDescription',
};
</script>

<style>
#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom:0;
  border-color: white;
}
#trust-us-card .content{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}
</style>
