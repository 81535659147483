import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

const STATE_VAR_SELECTED_LAYER_TYPE = 'SelectedLayerType';
const M_SET_SELECTED_LAYER_TYPE = 'SetSelectedLayerType';
const actions = {};

const getters = {
  [STATE_VAR_SELECTED_LAYER_TYPE]: GettersUtility.get(STATE_VAR_SELECTED_LAYER_TYPE),
};

const mutations = {
  [M_SET_SELECTED_LAYER_TYPE]: MutationsUtility.set(STATE_VAR_SELECTED_LAYER_TYPE),
};

const state = {
  [STATE_VAR_SELECTED_LAYER_TYPE]: [],
};

export default {
  actions,
  getters,
  mutations,
  state,
};
