import { GettersUtility } from '@afrigis/vuex-utilities';

const STATE_VAR_LAYER_TYPES = 'LayerTypes';
const actions = {};
const getters = {
  [STATE_VAR_LAYER_TYPES]: GettersUtility.get(STATE_VAR_LAYER_TYPES),
};

const state = {
  [STATE_VAR_LAYER_TYPES]: [
    {
      description: 'Heatmap',
      text: 'Heatmap',
      value: 'iris:sal_bloemfontein_population_centroid',
      style: 'iris_sal_centroid_heatmap',
      width: 162,
      height: 319,
    },
    /* {
      description: 'SAL Centroid Lightmap.',
      text: 'SAL Centroid Lightmap',
      value: 'iris:sal_bloemfontein_population_centroid',
      style: 'iris_sal_centroid_lightmap',
    } */
  ],
};

export default {
  actions,
  getters,
  state,
};
