import { GettersUtility } from '@afrigis/vuex-utilities';

const STATE_VAR_LAYER_TYPES = 'LayerTypes';
const actions = {};
const getters = {
  [STATE_VAR_LAYER_TYPES]: GettersUtility.get(STATE_VAR_LAYER_TYPES),
};

const state = {
  [STATE_VAR_LAYER_TYPES]: [
    {
      description: 'Demographic Boundary',
      text: 'Demographic Boundary',
      value: 'iris:sal_bloemfontein_population',
      style: 'iris_sal_boundary',
      width: 197,
      height: 89,
    },
    {
      description: 'Boundary Population',
      text: 'Boundary Population',
      value: 'iris:sal_bloemfontein_population',
      style: 'iris_sal_boundary_thematic',
      scale: 100000,
      width: 199,
      height: 233,
    },
    {
      description: 'Point',
      text: 'Point',
      value: 'iris:sal_bloemfontein_population_centroid',
      style: 'iris_sal_centroid',
      width: 193,
      height: 89,
    },
    {
      description: 'Point Thematic',
      text: 'Point Thematic',
      value: 'iris:sal_bloemfontein_population_centroid',
      style: 'iris_sal_centroid_thematic',
      scale: 10000,
      width: 202,
      height: 236,
    },
    {
      description: 'Point Cluster',
      text: 'Point Cluster',
      value: 'iris:sal_bloemfontein_population_centroid',
      style: 'iris_sal_centroid_cluster',
      width: 128,
      height: 139,
    },
  ],
};

export default {
  actions,
  getters,
  state,
};
