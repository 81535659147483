import Vue from 'vue';
import VueRouter from 'vue-router';
import MapLayers from '@/views/MapLayers.vue';
import HeatMapLayers from '@/views/HeatMapLayers.vue';
import ThematicMapLayers from '@/views/ThematicMapLayers.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/map-layer',
    name: 'MapLayers',
    component: MapLayers,
  },
  {
    path: '/map-layer/heat',
    name: 'HeatMapLayers',
    component: HeatMapLayers,
  },
  {
    path: '/map-layer/thematic',
    name: 'ThematicMapLayers',
    component: ThematicMapLayers,
  },
  {
    component: () => import(/* webpackChunkName: "Error" */'@/views/Error.vue'),
    meta: {
      isPublic: true,
    },
    name: 'Error',
    path: '/error',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
