<template>
  <b-dropdown
    class="mt-2 mr-2 layer-selector"
    no-caret
    right
    toggle-class="text-decoration-none"
    variant="primary"
  >
    <template #button-content>
      <b-icon-layers-half />
    </template>
    <div class="px-2">
      <h6 class="mb-2 font-weight-bold text-center">Map Layers</h6>
      <b-form-group v-slot="{ ariaDescribedby }" v-if="!singleSelector" >
        <b-form-checkbox
          v-for="option in LayerTypes"
          v-model="selected"
          :key="option.text"
          :value="option"
          :aria-describedby="ariaDescribedby"
          switch
        >
          {{ option.text }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group v-slot="{ ariaDescribedby }" v-else >
        <b-form-radio-group
          :aria-describedby="ariaDescribedby"
          name="selected-layer"
          :options="LayerTypes"
          stacked
          v-model="layerSelected"
          value-field="text"
        />
      </b-form-group>
    </div>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import {
  BDropdown,
  BFormCheckbox,
  BFormRadioGroup,
  BFormGroup,
  BIconLayersHalf,
} from 'bootstrap-vue';

export default {
  components: {
    BDropdown,
    BFormCheckbox,
    BFormRadioGroup,
    BFormGroup,
    BIconLayersHalf,
  },
  computed: {
    ...mapGetters({
      SelectedLayerType: 'selectedMapLayersStore/SelectedLayerType',
    }),
    selected: {
      get() {
        if (!this.SelectedLayerType || !this.SelectedLayerType.length) {
          return [];
        }
        return this.SelectedLayerType;
      },
      set(selected) {
        const types = this.LayerTypes.filter((type) => selected.some((s) => s === type));
        this.SetSelectedLayerType(types);
      },
    },
    layerSelected: {
      get() {
        return this.SelectedLayerType[0]?.text;
      },
      set(value) {
        const newLayer = this.LayerTypes.find((layer) => layer.text === value);
        this.SetSelectedLayerType([newLayer]);
      },
    },
  },
  methods: {
    ...mapMutations({
      SetSelectedLayerType: 'selectedMapLayersStore/SetSelectedLayerType',
    }),
  },
  props: {
    singleSelector: {
      default: false,
      type: Boolean,
    },
    LayerTypes: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>
.layer-selector {
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 500;
  cursor: pointer;
}
</style>
